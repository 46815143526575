@import "ProjectList";
.TimelinePanel {
  position: fixed;
  pointer-events: none;

  .tPanel {
    position: absolute;
    display: flex;
    flex-flow: column;
    top:$panel-space;
    bottom: $panel-space;
    left: 0;
    right: $panel-space;
  }

  .panel__header {
      z-index: 1;
  }

  .ReactForm__RadioSlider {
      height: 100%;

      &-option {
          padding: 6px;
          font-size: $font_size-small;
      }
  }

  .panel--closed {
    position: absolute;
    right: $panel-space;
    left: auto;
    width: $dims_button-size + $dims_padding + 22.143rem;
    overflow: hidden;

    .temp-buttons, .color-selector, .resort-button {
      display: none;
    }
  }

  .panel-toggle {
    display:flex;
    flex: 0.75;
    button {
      transform: rotate(-90deg);//we want the slider button to appear sideways
    }
  }

  .color-selector {
    flex: 1 1;
    max-width: 245px;
    vertical-align: middle;
    margin: 0 0 0 .5rem;
    text-transform: uppercase;
  }

  .resort-button {
      height: 100%;
      padding: $dims_padding;

      color: $color_font-dark;
      font-size: $font_size-small;
      text-transform: uppercase;

      background: $color_bg-medium;
      border-radius: $box_border-radius;
  }

  .timelineHolder{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex: 1;
    overflow-y: auto;
  }

    .timeline-menu {
        position: absolute;
        top: calc(28px + #{$dims_padding * 2});
        right: $dims_padding-large;

        width: 14px;
        height: 28px;

        background: $color_bg-light;
        border-radius: $box_border-radius--small;
        border: 1px solid $color_bg-medium;
        box-shadow: $box_shadow;
        overflow: hidden;
        cursor: pointer;
        transition: width .25s;

        &:hover {
            width: 120px;
            height: auto;
            transition: width .25s;
        }

        .menu-hamburger {
            width: 3px;
            padding: 3px 0;

            .dot, &:before, &:after {
                content: '';
                display: block;

                width: 100%;
                height: 3px;
                margin: 2px 5px;

                background: $color_font-medium;
                border-radius: $box_border-radius--small;
            }

            span {
                position: absolute;
                left: 14px;
                top: 55%;
                transform: translateY(-50%);

                font-size: $font_size-small;
                font-weight: 400;
            }
        }

        ul {
            width: 120px;
            list-style: none;
            padding: 0;
            margin: 0;
            margin-top: 5px;

            button {
                width: 100%;
                text-align: left;
                background: $color_bg-medium;
                font-weight: 500;
                font-size: $font_size-small;

                &:hover {
                    color: $color_light-blue;
                    background: $color_lightest-blue;
                }
            }
        }
    }

}
