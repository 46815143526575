.TimeSlider {
    flex: 1 0 auto;
    display: flex;
    align-items: center;

    padding: 0 $dims_padding;
    margin-left: .5rem;

    background-color: $color_bg-medium;
    border-radius: $box_border-radius--small;

    .title {
      width: 5.91rem;
      text-transform: uppercase;

      font-size: $font_size-regular;

      .time-number {
        display: inline-block;
        margin: 0;
        text-align: right;
      }
    }

    .slider-comp {
        flex: 1 0 auto;
        margin: auto $dims_padding;
      .rc-slider {
        .rc-slider-rail {
          background-color: $color_bg-light;
        }
        .rc-slider-track{
          background-color: $color_light-blue;
        }
        .rc-slider-handle {
            border: none;
          background-color: $color_font-dark;
          margin-top: -3px;
          height: $font_size-regular;
          width: $font_size-regular;

          &:hover {
              box-shadow: $box_shadow;
          }
        }
      }
    }

    button {
        display: flex;
        flex: 0 1 min-content;
        align-content: middle;
        padding: 0;
        margin: 0;
        background: none;

        .icon {
            width: $font_size-medium;
            height: $font_size-medium;
            margin: auto;
            vertical-align: middle;

            stroke: $color_light-blue;

            &:hover {
                stroke: $color_blue;
            }
        }
    }
}
