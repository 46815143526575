.GlobalSettingsModal {

    .content {
        padding: $dims_padding-large * 4 !important;
    }

    .modal-buttons {
        padding: 0 $dims_padding-large $dims_padding-large 0;
    }

    .ReactModal {
        &__window {
            width: 90vw;
            max-width: 800px;
        }
    }

    .tag {
        margin-top: $dims_padding-large;
        font-size: 1.166rem;
        color: $color_font-medium;
    }

    .ReactForm {
        &__Input {
            width: 200px;

            + .ReactForm__Label {
                padding-top: $dims_padding-large;
                margin-top: $dims_padding-large;
                border-top: 1px solid $color_bg-dark;
            }

            input {
                outline: none;
            }
        }

        &__Label {
            width: 100%;

            .assumptions {
                border-radius: $box_border-radius--medium;
                padding: $dims_padding-large;
                background: $color_lightest-blue;
            }

            p {
                width: 100%;
                font-size: 1.166rem;
                font-weight: 400;

                margin: 0;

                + p {
                    margin-top: $dims_padding-large;
                }
            }
        }
    }

}
