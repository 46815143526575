.ChartsHeader {
    $_height: 38px;

    display: flex;
    flex: 0 1 $_height;
    height: $_height;
    align-items: center;

    padding: 0 !important;

    h5 {
        flex: 1 1 auto;

        margin: 0;
        padding: 0 $dims_padding;

        font-weight: 500;
        font-size: $font_size-regular;
    }

    .chart-legend {
        display: flex;
        flex: 0 1 $_height;

        height: $_height;

        margin: 0;
        background: $color_bg-medium;
        overflow: visible;

        svg {
            margin: auto;
            z-index: 3;
        }

        &:hover {
            ul {
                opacity: 1;
                pointer-events: initial;

                * {
                    pointer-events: initial;
                }
            }
        }
    }

    ul {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;

        width: max-content;
        margin: 0;
        padding: $dims_padding-large;

        background: rgba($color_bg-medium, .85);
        list-style: none;
        opacity: 0;
        pointer-events: none;
        transition: opacity .15s;

        * {
            pointer-events: none;
        }
    }

    li {
        + li {
            margin-top: .25em;
        }
      div {
        vertical-align: middle;
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: #57fac6;
        border-radius: 50%;
      }

      span {
        vertical-align: middle;
        display: inline-block;
        margin-left: 1em;
        white-space: pre;
        font-weight: 500;
        font-family: "Inter", sans-serif;
        color:  #050038;
        font-size: 10px;
        letter-spacing: 0;
        line-height: 0.9;
        text-transform: uppercase;
        text-align: left;
      }
  }

}
