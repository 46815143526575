.ProjectSettings {
    $this: &;
    $_width: 60.143rem;

    position: fixed;
    z-index: 10;

    width: 100%;
    height: 100%;

    opacity: 0;
    pointer-events: none;
    transition: opacity .3s;

    &.active {
        opacity: 1;
        pointer-events: all;
        transition: opacity .3s;

        #{$this}-window {
            left: 0;
            transition: left .6s;
        }
    }

    &-gutter {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background: rgba($color_bg-dark, .4);
    }

    &-window {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: -$_width;

        width: $_width;
        height: 100vh;

        background: $color_bg-light;
        border-radius: 0 $box_border-radius--medium $box_border-radius--medium 0;
        overflow: hidden;
        transition: left .6s;
    }

    &-header {
        padding: $dims_padding-large;

        color: $color_light-blue;
        font-size: $font_size-large;
        font-weight: 400;

        background: $color_lightest-blue;
    }

    &-form {
        flex: 1 auto;
        overflow-y: auto;
        padding: 0 $dims_padding-large;
    }

    section {
        padding: $dims_padding-large;

        + section {
            border-top: 1px solid $color_bg-dark;
        }

        input {
            outline: none;
        }

        .ReactForm__Input {
            width: 125px;
        }

        .cost-sibling + .ReactForm__Input {
            flex: 0 0 150px;
        }

        &.top {
            .hide-labels {
                .ReactForm__Input {
                    width: 140px;
                }
            }

            .project-name {
                flex: 1 1 325px;
            }
        }

        &.info {
            .ReactForm__Input {
                width: auto;
                flex: 1 1;
            }
        }

        &.fundraising {
            .ReactForm__Group-children {
                align-items: last baseline;
            }

            .cost-sibling {
                display: flex;
                align-items: last baseline;

                .ReactForm__Input {
                    width: 250px;
                }

                button {
                    top: -$dims_padding;

                    padding: $dims_padding * 0.5 $dims_padding;
                    margin: 0 0 0 $dims_padding * 2;

                    color: $color_light-blue;
                    font-size: $font_size-small;
                    font-weight: 500;

                    background: $color_bg-light;
                    border-radius: $box_border-radius--medium;
                    box-shadow: $box_shadow;

                    &:hover {
                        background: $color_lightest-blue;
                    }
                }
            }
        }

        &.timestamps {
            .ReactForm__Group-children {
                align-items: top;
            }

            .month, select {
                width: 150px;
            }

            .year {
                width: 90px;
            }

            .quarter {
                width: 45px;

                text-align: center;

                label {
                    position: absolute;
                    left: 0;
                    right: 9;
                    margin-left: auto;
                    margin-right: auto;
                }

                .ReactForm__Input-input {
                    width: 45px !important;
                    margin: auto;
                }
            }

            .duration {
                margin-left: 3em;
                width: 165px;
            }
        }

        textarea {
            height: 10em;
            resize: none;
        }

        .cost-sibling {
            flex: 1 1 auto;
        }
    }

    .AccessOption {
        + .AccessOption {
            margin-top: .2em;
        }

        .ReactForm__Group-children {
            align-items: center;
        }

        .ReactForm__Input:not(.readonly) {
            width: 95px;
        }

        label {
            width: 85px;
            font-weight: 400;
            text-transform: none;
        }
    }

    .finish-axons {
        width: 100%;
        margin-top: 1em;

        img {
            width: 50%;
            left: -10%;

            &:nth-of-type(2) {
                left: 5%;
            }
        }
    }

    &-footer {
        padding: $dims_padding;
        text-align: right;

        button:not(.delete-project) {
            font-weight: 500;
            font-size: $font_size-medium;

            border-radius: $box_border-radius--small;
            box-shadow: $box_shadow;

            + button {
                margin-left: $dims_padding;
            }
        }

        .delete-project {
            float: left;
        }
    }
}

.delete-project-confirmation {
    p {
        margin-top: 0;
        font-weight: 400;
    }
}

